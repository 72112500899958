<template>
  <div v-if="!isJSON">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            dense
            :disabled="isCreated"
            label="Id*"
            v-model="entities.id"
            :counter="50"
            :rules="commonRules"
            required
            hint="example of helper text only on focus"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            dense
            :disabled="isCreated"
            label="Type*"
            v-model="entities.type"
            :counter="50"
            :rules="commonRules"
            required
            hint="example of helper text only on focus"
          ></v-text-field>
        </v-col>
        <!-- END-ROW -->
        <v-col
          cols="12"
          sm="11"
        >
          <p class="text-button">Attribute *</p>
        </v-col>
        <v-col
          cols="12"
          sm="1"
        >
          <v-btn
            class="mt-0"
            fab
            dark
            x-small
            color="success"
            @click="addEntitiesAttr()"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
        <!-- END-ROW -->
        <v-col
          v-if="entities.attributes && entities.attributes.length > 0"
          cols="12"
          class="mt-n5"
        >
          <v-expansion-panels
            focusable
            v-model="panel"
          >
            <v-expansion-panel
              v-for="(attr, attrIndex) in entities.attributes"
              :key="attrIndex"
            >
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                {{attr.attrName}}
                <!-- <v-layout
                  justify-end
                >
                  <v-btn
                    right
                    x-small
                    icon
                    color="pink"
                  >
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                </v-layout> -->
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <v-text-field
                      dense
                      label="Attribute Name*"
                      v-model="attr.attrName"
                      :counter="50"
                      :rules="commonRules"
                      required
                      hint="example of helper text only on focus"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-combobox
                      dense
                      label="Type (optional)"
                      required
                      v-model="attr.type.type"
                      :items="attr.type.items"
                    ></v-combobox>
                  </v-col>
                  <!-- END-ROW -->
                  <v-col
                    cols="12"
                  >
                    <v-btn-toggle
                      dense
                      v-model="attr.attrValueType"
                      rounded
                    >
                      <v-btn
                        rounded
                        small
                        value="single"
                      >Single-value</v-btn>
                      <v-btn
                        rounded
                        small
                        value="object"
                      >Object-value</v-btn>
                      <v-btn
                        rounded
                        small
                        value="array"
                      >Array-value</v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <template v-if="attr.attrValueType === 'single'">
                    <v-col
                      cols="12"
                      sm="2"
                    >
                      <div class="subtitle-1 text--secondary">Value *</div>
                    </v-col>
                    <v-col
                      v-if="attr.type.type === 'Boolean'"
                      cols="12"
                      sm="10"
                    >
                      <v-radio-group
                        mandatory
                        class="mt-0"
                        v-model="attr.values.singleValue"
                        row
                        dense
                      >
                        <v-radio
                          label="True"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="False"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      v-else-if="attr.type.type === 'Number'"
                      cols="12"
                      sm="10"
                    >
                      <v-text-field
                        dense
                        label="Value*"
                        v-model.number="attr.values.singleValue"
                        required
                        :rules="numberRules"
                        hint="example of helper text only on focus"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-else-if="attr.type.type === 'Text'"
                      cols="12"
                      sm="10"
                    >
                      <v-text-field
                        dense
                        label="Value*"
                        v-model="attr.values.singleValue"
                        required
                        :counter="50"
                        :rules="textRules"
                        hint="example of helper text only on focus"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-else-if="attr.type.type === 'geo:json'"
                      cols="12"
                      sm="10"
                    >
                      <v-text-field
                        dense
                        label="Value*"
                        v-model="attr.values.singleValue"
                        required
                        :counter="50"
                        :rules="geoJSONRules"
                        hint="example of helper text only on focus"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-else-if="attr.type.type === 'geo:point'"
                      cols="12"
                      sm="10"
                    >
                      <v-text-field
                        dense
                        label="Value*"
                        v-model="attr.values.singleValue"
                        required
                        :counter="50"
                        :rules="geoRules"
                        hint="example of helper text only on focus"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-else
                      cols="12"
                      sm="10"
                    >
                      <v-text-field
                        dense
                        label="Value*"
                        v-model="attr.values.singleValue"
                        required
                        :counter="50"
                        :rules="commonRules"
                        hint="example of helper text only on focus"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <template v-else-if="attr.attrValueType === 'object'">
                    <v-col
                      class="mt-n6 mb-0 d-flex"
                      cols="12"
                    >
                      <v-checkbox
                        class="mr-2"
                        dense
                        v-model="attr.values.objectValue.objType"
                        label="Key-value"
                        value="keyValue"
                      ></v-checkbox>
                      <v-checkbox
                        class="mr-2"
                        dense
                        label="Object"
                        v-model="attr.values.objectValue.objType"
                        value="object"
                      ></v-checkbox>
                      <v-checkbox
                        class="mr-2"
                        dense
                        label="Array"
                        v-model="attr.values.objectValue.objType"
                        value="array"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-row
                        v-for="(objValueType, objValueTypeIndex) in attr.values.objectValue.objType"
                        :key="objValueTypeIndex"
                      >
                        <template v-if="objValueType === 'keyValue'">
                          <v-col
                            cols="12"
                          >
                            <p class="overline text-decoration-underline">Key-Value</p>
                          </v-col>
                          <v-col
                            class="mt-n4"
                            cols="12"
                          >
                            <v-row
                              class="mb-n5"
                              v-for="(objKeyValue, objKeyValueIndex) in attr.values.objectValue.keyValue"
                              :key="objKeyValueIndex"
                            >
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-text-field
                                  dense
                                  label="Key*"
                                  v-model="objKeyValue.key"
                                  required
                                  :counter="50"
                                  :rules="objKeyValueIndex + 1 == attr.values.objectValue.keyValue.length && objKeyValueIndex > 0 ? [] : commonRules"
                                  @input="addAttributeValueKv(attrIndex, objKeyValueIndex)"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-text-field
                                  dense
                                  label="Value*"
                                  v-model="objKeyValue.value"
                                  required
                                  :counter="50"
                                  :rules="objKeyValueIndex + 1 == attr.values.objectValue.keyValue.length && objKeyValueIndex > 0 ? [] : commonRules"
                                  @input="addAttributeValueKv(attrIndex, objKeyValueIndex)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-if="objValueType === 'object'">
                          <v-col
                            cols="12"
                          >
                            <p class="overline text-decoration-underline">Object</p>
                          </v-col>
                          <v-col
                            class="mt-n4"
                            cols="12"
                          >
                            <v-row
                              v-for="(objValue, objValueIndex) in attr.values.objectValue.objValue"
                              :key="objValueIndex"
                            >
                              <v-col
                                cols="12"
                              >
                                <v-text-field
                                  dense
                                  label="Object name"
                                  v-model="objValue.objName"
                                  required
                                  :counter="50"
                                  :rules="objValueIndex + 1 == attr.values.objectValue.objValue.length && objValueIndex > 0 ? [] : commonRules"
                                  @input="addAttributeValueObj(attrIndex, objValueIndex)"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                class="mt-n4"
                                cols="12"
                              >
                                <v-row
                                  class="ml-4"
                                  v-for="(objKeyValue, objKeyValueIndex) in objValue.keyValue"
                                  :key="objKeyValueIndex"
                                >
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      dense
                                      label="Key"
                                      v-model="objKeyValue.key"
                                      required
                                      @input="addAttributeValueObj(attrIndex, objValueIndex, objKeyValueIndex)"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      dense
                                      label="Value"
                                      required
                                      v-model="objKeyValue.value"
                                      @input="addAttributeValueObj(attrIndex, objValueIndex, objKeyValueIndex)"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-if="objValueType === 'array'">
                          <v-col
                            class="mb-n5"
                            cols="12"
                          >
                            <p class="overline text-decoration-underline">Array</p>
                          </v-col>
                          <v-col
                            class="mt-n4"
                            cols="12"
                          >
                            <v-row
                              v-for="(arrValue, arrValueIndex) in attr.values.objectValue.arrayValue"
                              :key="arrValueIndex"
                            >
                              <v-col
                                class="mt-4"
                                cols="12"
                                sm="4"
                              >
                                <v-text-field
                                  dense
                                  label="name"
                                  required
                                  :counter="50"
                                  v-model="arrValue.key"
                                  :rules="arrValueIndex + 1 == attr.values.objectValue.arrayValue.length && arrValueIndex > 0 ? [] : commonRules"
                                  @input="addAttributeValueArr(attrIndex, arrValueIndex)"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="8"
                              >
                                <v-combobox
                                  v-model="arrValue.value"
                                  :search-input.sync="arrValue.inputArr"
                                  hide-selected
                                  label="Add array tags"
                                  multiple
                                  persistent-hint
                                  small-chips
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Press <kbd>enter</kbd> to create a new one
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-combobox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </template>
                  <template v-else-if="attr.attrValueType === 'array'">
                    <v-col
                      class="d-flex"
                      cols="12"
                    >
                      <v-checkbox
                        class="mr-2"
                        dense
                        v-model="attr.values.arrayValue.arrtype"
                        label="Value"
                        value="value"
                      ></v-checkbox>
                      <v-checkbox
                        class="mr-2"
                        dense
                        v-model="attr.values.arrayValue.arrtype"
                        label="Key-value"
                        value="keyValue"
                      ></v-checkbox>
                      <v-checkbox
                        class="mr-2"
                        dense
                        v-model="attr.values.arrayValue.arrtype"
                        label="Array"
                        value="array"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-row
                        v-for="(arrValueType, arrValueTypeIndex) in attr.values.arrayValue.arrtype"
                        :key="arrValueTypeIndex"
                      >
                        <template v-if="arrValueType === 'value'">
                          <v-col
                            class="mb-n5"
                            cols="12"
                          >
                            <p class="overline text-decoration-underline">Value</p>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <v-combobox
                              v-model="attr.values.arrayValue.singleValue.values"
                              :search-input.sync="attr.values.arrayValue.singleValue.inputValue"
                              hide-selected
                              label="Add array tags"
                              multiple
                              persistent-hint
                              small-chips
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Press <kbd>enter</kbd> to create a new one
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </v-col>
                        </template>
                        <template v-if="arrValueType === 'keyValue'">
                          <v-col
                            class="mb-n5"
                            cols="12"
                          >
                            <p class="overline text-decoration-underline">Object</p>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <v-row
                              class="mt-n4"
                              v-for="(arrayObjValue, arrayObjValueIndex) in attr.values.arrayValue.keyValue"
                              :key="arrayObjValueIndex"
                            >
                              <v-col
                                cols="12"
                                sm="6"
                              ></v-col>
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-btn-toggle
                                  dense
                                  v-model="arrayObjValue.valueType"
                                  rounded
                                  mandatory
                                >
                                  <v-btn
                                    rounded
                                    x-small
                                    value="string"
                                    @click="arrayObjValue.value = null"
                                  >String</v-btn>
                                  <v-btn
                                    rounded
                                    x-small
                                    value="number"
                                    @click="arrayObjValue.value = null"
                                  >Number</v-btn>
                                  <v-btn
                                    rounded
                                    x-small
                                    value="array"
                                    @click="arrayObjValue.value = null"
                                  >Array</v-btn>
                                </v-btn-toggle>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <v-text-field
                                  dense
                                  label="Key*"
                                  required
                                  :counter="50"
                                  :rules="arrayObjValueIndex + 1 == attr.values.arrayValue.keyValue.length && arrayObjValueIndex > 0 ? [] : commonRules"
                                  v-model="arrayObjValue.key"
                                  @input="addAttrArrayKv(attrIndex, arrayObjValueIndex)"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="6"
                              >
                                <template v-if="arrayObjValue.valueType === 'string'">
                                  <v-text-field
                                    dense
                                    label="Value*"
                                    required
                                    :counter="50"
                                    :rules="arrayObjValueIndex + 1 == attr.values.arrayValue.keyValue.length && arrayObjValueIndex > 0 ? [] : commonRules"
                                    v-model="arrayObjValue.value"
                                    @input="addAttrArrayKv(attrIndex, arrayObjValueIndex)"
                                  ></v-text-field>
                                </template>
                                <template v-else-if="arrayObjValue.valueType === 'number'">
                                  <v-text-field
                                    dense
                                    label="Value*"
                                    required
                                    :counter="50"
                                    :rules="arrayObjValueIndex + 1 == attr.values.arrayValue.keyValue.length && arrayObjValueIndex > 0 ? [] : typeofNumberRules"
                                    v-model.number="arrayObjValue.value"
                                    @input="addAttrArrayKv(attrIndex, arrayObjValueIndex)"
                                  ></v-text-field>
                                </template>
                                <template v-else-if="arrayObjValue.valueType === 'array'">
                                  <v-combobox
                                    class="mt-n3"
                                    v-model="arrayObjValue.value"
                                    :search-input.sync="arrayObjValue.inputValue"
                                    hide-selected
                                    label="Add array tags"
                                    multiple
                                    persistent-hint
                                    small-chips
                                    @input="addAttrArrayKv(attrIndex, arrayObjValueIndex)"
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Press <kbd>enter</kbd> to create a new one
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-combobox>
                                  <!-- <v-text-field
                                    dense
                                    label="Value*"
                                    required
                                    :counter="50"
                                    :rules="arrayObjValueIndex + 1 == attr.values.arrayValue.keyValue.length && arrayObjValueIndex > 0 ? [] : commonRules"
                                    v-model="arrayObjValue.value"
                                  ></v-text-field> -->
                                </template>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-if="arrValueType === 'array'">
                          <v-col
                            class="mb-n5 d-flex"
                            cols="12"
                          >
                            <p class="overline text-decoration-underline">Array</p>
                            <v-btn
                              class="ml-auto"
                              icon
                              color="success"
                              @click="addAttributeValueArrayArr(attrIndex)"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <v-row
                              class="mt-n4"
                              v-for="(arrayArrValue, arrayArrValueIndex) in attr.values.arrayValue.arrValue.arr"
                              :key="arrayArrValueIndex"
                            >
                              <v-col
                                cols="12"
                              >
                                <v-combobox
                                  v-model="arrayArrValue.values"
                                  :search-input.sync="arrayArrValue.inputValue"
                                  hide-selected
                                  label="Add array tags"
                                  multiple
                                  persistent-hint
                                  small-chips
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Press <kbd>enter</kbd> to create a new one
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-combobox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </template>
                  <!-- END-ROW -->
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <div class="subtitle-1 text--secondary">Metadata</div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    <v-row
                      v-for="(meta, metaIndex) in attr.metadata"
                      :key="metaIndex"
                    >
                      <v-col
                        class="mt-n1"
                        cols="12"
                        lg="4"
                        md="6"
                      >
                        <v-text-field
                          dense
                          label="Metadata name*"
                          v-model="meta.name"
                          hint="example of helper text only on focus"
                          @input="addAttrMetadata(attrIndex, metaIndex)"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="mt-n1"
                        cols="12"
                        lg="4"
                        md="6"
                      >
                        <v-text-field
                          dense
                          label="Value*"
                          v-model="meta.value"
                          hint="example of helper text only on focus"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="mt-n1"
                        cols="12"
                        lg="4"
                        md="12"
                      >
                        <v-text-field
                          dense
                          label="Type (optional)"
                          v-model="meta.type"
                          hint="example of helper text only on focus"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="d-flex">
                  <div class="text-left">
                    <v-btn
                      class="mx-2"
                      fab
                      x-small
                      dark
                      color="secondary"
                      @click="duplicateAttributes(attr)"
                    >
                      <v-icon dark>
                        mdi-content-duplicate
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ml-auto text-right">
                    <v-btn
                      class="mx-2"
                      fab
                      x-small
                      dark
                      color="danger"
                      @click="deleteEntitiesAttr.isdelete = true, deleteEntitiesAttr.attrName = attr.attrName, deleteEntitiesAttr.index = attrIndex"
                    >
                      <v-icon dark>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <confirm-dialog v-model="deleteEntitiesAttr.isdelete" type="delete" :title="deleteEntitiesAttr.attrName" @confirm="confirmDeleteEntitiesAttr(deleteEntitiesAttr.index)"></confirm-dialog>
          <!-- <confirm-delete v-model="deleteEntitiesAttr.isdelete" :title="deleteEntitiesAttr.attrName" @delete="confirmDeleteEntitiesAttr(deleteEntitiesAttr.index)"></confirm-delete> -->
        </v-col>
      </v-row>
      <small>*indicates required field</small>
      <snack-bar v-model="snackActive" :text="snackMessage"></snack-bar>
    </v-form>
  </div>
  <div v-else>
    <v-jsoneditor v-model="filterEntities" :options="{ mode: 'code' }" :plus="false" height="400px" @error="jsoneditorError($event)"></v-jsoneditor>
  </div>
</template>

<script>
import api from '@/services/api'

var attributesForm = {
  attrName: null,
  type: {
    items: ['Boolean', 'Number', 'Text', 'geo:json', 'geo:point'],
    type: null
  },
  attrValueType: 'single',
  values: {
    singleValue: null,
    objectValue: {
      objType: ['keyValue'],
      keyValue: [
        {
          key: null,
          value: null
        }
      ],
      objValue: [
        {
          objName: null,
          keyValue: [
            {
              key: null,
              value: null
            }
          ]
        }
      ],
      arrayValue: [
        {
          key: null,
          value: [],
          inputArr: null
        }
      ]
    },
    arrayValue: {
      arrtype: ['value'],
      singleValue: {
        values: [],
        inputValue: null
      },
      keyValue: [
        {
          key: null,
          value: null,
          inputValue: null
        }
      ],
      arrValue: {
        arr: [
          {
            values: [],
            inputValue: null
          }
        ]
      }
    }
  },
  metadata: [{
    name: null,
    value: null,
    type: null
  }]
}

Object.freeze(attributesForm)

function isString (value) {
  return typeof value === 'string' || value instanceof String
}
function isNumber (value) {
  return typeof value === 'number' && isFinite(value)
}
function isObject (value) {
  return value && typeof value === 'object' && value.constructor === Object
}
function isArray (value) {
  return value && typeof value === 'object' && value.constructor === Array
}

export default {
  name: 'FiwareEntitiesForm',
  components: {
    SnackBar: () => import('@/components/SnackBar'),
    ConfirmDialog: () => import('@/components/ConfirmDialog.vue')
  },
  props: {
    isJSON: {
      type: Boolean,
      required: false,
      default: false
    },
    entities: {
      type: Object,
      required: true,
      default: function () {
        return {
          id: null,
          type: null,
          attributes: [JSON.parse(JSON.stringify(attributesForm))]
        }
      }
    },
    isCreated: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      panel: [],
      deleteEntitiesAttr: {
        isdelete: false,
        attrName: '',
        index: -1
      },
      snackActive: false,
      snackMessage: null
      // attrIndex: -1
    }
  },
  computed: {
    commonRules: function () {
      return [
        v => !!v || 'field is required',
        v => (v && v.length <= 50) || 'field must be less than 50 characters'
      ]
    },
    optionalRules: function () {
      return [
        v => (v && v.length <= 50) || 'field must be less than 50 characters'
      ]
    },
    typeofNumberRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+\.\d+)$|^(-?\d+)$/i.test(v) || 'field must be number'
      ]
    },
    numberRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+\.\d+)$|^(-?\d+)$/i.test(v) || 'field must be number'
      ]
    },
    textRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^[a-zA-Z ]*$/i.test(v) || 'field must be text',
        v => (v && v.length <= 50) || 'field must be less than 50 characters'
      ]
    },
    geoJSONRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/i.test(v) || 'field must be geolocation'
      ]
    },
    geoRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/i.test(v) || 'field must be geolocation'
      ]
    },
    filterEntities: {
      get () {
        let entities = {}
        if (!this.isCreated) {
          Object.assign(entities, { id: this.entities.id, type: this.entities.type })
        }
        for (const attr of this.entities.attributes) {
          let attrVal = null
          if (attr.attrValueType === 'single') {
            attrVal = attr.values.singleValue
          } else if (attr.attrValueType === 'object') {
            let obj = {}
            attr.values.objectValue.objType.forEach(type => {
              if (type === 'keyValue') {
                attr.values.objectValue.keyValue.filter(keyValue => keyValue.key !== null || keyValue.value !== null).forEach(kv => {
                  Object.assign(obj, { [kv.key]: kv.value })
                })
              } if (type === 'object') {
                attr.values.objectValue.objValue.filter(objValue => objValue.objName !== null).forEach(ov => {
                  let keyVal = {}
                  ov.keyValue.filter(keyValue => keyValue.key !== null || keyValue.value !== null).forEach(kv => {
                    Object.assign(keyVal, { [kv.key]: kv.value })
                  })
                  Object.assign(obj, { [ov.objName]: keyVal })
                })
              } if (type === 'array') {
                attr.values.objectValue.arrayValue.filter(arrayValue => arrayValue.key !== null).forEach(av => {
                  Object.assign(obj, { [av.key]: av.value })
                })
              }
              attrVal = obj
            })
          } else if (attr.attrValueType === 'array') {
            attrVal = []
            let vals = []
            let arrKv = {}
            let arrVal = []
            attr.values.arrayValue.arrtype.forEach(type => {
              if (type === 'value') {
                vals = attr.values.arrayValue.singleValue.values
              }
              if (type === 'keyValue') {
                attr.values.arrayValue.keyValue.forEach(kv => {
                  if (kv.key) {
                    Object.assign(arrKv, { [kv.key]: kv.value })
                  }
                })
              }
              if (type === 'array') {
                attr.values.arrayValue.arrValue.arr.forEach(arr => {
                  arrVal.push(arr.values)
                })
              }
            })
            if (vals.length > 0) {
              attrVal.push(...vals)
            }
            if (Object.getOwnPropertyNames(arrKv).filter(k => k !== null).length > 0) {
              attrVal.push(arrKv)
            }
            if (arrVal.length > 0) {
              attrVal.push(...arrVal)
            }
          }
          entities[attr.attrName] = {
            value: attrVal
          }
          if (attr.type.type) {
            entities[attr.attrName].type = attr.type.type
          }
          const metadata = {}
          attr.metadata.filter(meta => meta.name !== null).forEach(mt => {
            metadata[mt.name] = {
              value: mt.value
            }
          })
          entities[attr.attrName].metadata = metadata
        }
        return entities
      },
      set (entities) {
        // console.log(entities)
        const entitiesSet = {
          id: this.entities.id,
          type: this.entities.type,
          attributes: []
        }
        let kv = []
        let obj = []
        let arr = []
        let objType = []
        let arrtype = []
        if (entities.id) entitiesSet.id = entities.id
        if (entities.type) entitiesSet.type = entities.type
        for (const [key, value] of Object.entries(entities)) {
          if (key !== 'id' && key !== 'type') {
            const attrObj = JSON.parse(JSON.stringify(attributesForm))
            attrObj.attrName = key
            for (const [attrKey, attrValue] of Object.entries(value)) {
              if (attrKey === 'type') {
                attrObj.type.type = attrValue
              } else if (attrKey === 'value') {
                // =================== ATTRIBUTE VALUE ====================
                if (isObject(attrValue)) {
                  // *** Object Value
                  attrObj.attrValueType = 'object'
                  for (const [valKey, valValue] of Object.entries(attrValue)) {
                    if (isString(valValue)) {
                      objType.push('keyValue')
                      kv.push({ key: valKey, value: valValue })
                    } else if (isObject(valValue)) {
                      objType.push('object')
                      let kv = []
                      for (const [valObjKey, valObjValue] of Object.entries(valValue)) {
                        kv.push({ key: valObjKey, value: valObjValue })
                      }
                      obj.push({ objName: valKey, keyValue: kv })
                    } else if (isArray(valValue)) {
                      objType.push('array')
                      arr.push({ key: valKey, value: valValue, inputArr: null })
                    }
                  }
                  if (kv.length > 0) attrObj.values.objectValue.keyValue = kv
                  if (obj.length > 0) attrObj.values.objectValue.objValue = obj
                  if (arr.length > 0) attrObj.values.objectValue.arrayValue = arr
                  attrObj.values.objectValue.objType = objType.filter((v, i, a) => a.indexOf(v) === i)
                } else if (isArray(attrValue)) {
                  // *** Array Value
                  let singleVal = []
                  let kVal = []
                  let arrVal = []
                  attrObj.attrValueType = 'array'
                  attrValue.forEach(element => {
                    if (isString(element)) {
                      arrtype.push('value')
                      singleVal.push(element)
                    } else if (isObject(element)) {
                      arrtype.push('keyValue')
                      for (const [key, value] of Object.entries(element)) {
                        let kv = { key: key, valueType: 'string', value: value, inputValue: null }
                        if (isNumber(value)) {
                          kv.valueType = 'number'
                        } else if (isString(value)) {
                          kv.valueType = 'string'
                        } else if (isArray(value)) {
                          kv.valueType = 'array'
                        }
                        kVal.push(kv)
                      }
                    } else if (isArray(element)) {
                      arrtype.push('array')
                      arrVal.push({ values: element, inputValue: null })
                    }
                  })
                  if (singleVal.length > 0) attrObj.values.arrayValue.singleValue = { values: singleVal, inputValue: null }
                  if (kVal.length > 0) attrObj.values.arrayValue.keyValue = kVal
                  if (arrVal.length > 0) attrObj.values.arrayValue.arrValue.arr = arrVal
                  attrObj.values.arrayValue.arrtype = arrtype.filter((v, i, a) => a.indexOf(v) === i)
                } else {
                  attrObj.values.singleValue = attrValue
                }
              } else if (attrKey === 'metadata') {
                const metadatas = []
                for (const [metaKey, metaValue] of Object.entries(attrValue)) {
                  const meta = {
                    name: metaKey
                  }
                  // attrObj.metadata.name = metaKey
                  for (const [mnKey, mnValue] of Object.entries(metaValue)) {
                    if (mnKey === 'value') {
                      Object.assign(meta, { value: mnValue })
                      // attrObj.metadata.value = mnValue
                    } else if (mnKey === 'type') {
                      // attrObj.metadata.hasType = true
                      // attrObj.metadata.type = mnValue
                      Object.assign(meta, { type: mnValue })
                    }
                  }
                  metadatas.push(meta)
                }
                metadatas.push({ name: null, value: null, type: null })
                attrObj.metadata = metadatas
              }
            }
            entitiesSet.attributes.push(attrObj)
          }
        }
        this.$emit('update:entities', entitiesSet)
        // this.entities = entitiesSet
      }
    }
  },
  methods: {
    tt: function (e) {
      console.log(e)
    },
    addEntitiesAttr: function () {
      this.entities.attributes.push(JSON.parse(JSON.stringify(attributesForm)))
      // this.panel.push(this.entitiesForm.attributes.length - 1)
    },
    addAttributeValueArrayArr: function (attrIndex) {
      this.entities.attributes[attrIndex].values.arrayValue.arrValue.arr.push({
        values: [],
        inputValue: null
      })
    },
    confirmDeleteEntitiesAttr: function (attrIndex) {
      this.entities.attributes = this.entities.attributes.filter((attr, index) => index !== attrIndex)
    },
    addAttributeValueKv: function (attrIndex, objKeyValueIndex) {
      if (this.entities.attributes[attrIndex].values.objectValue.keyValue.length === objKeyValueIndex + 1) {
        this.entities.attributes[attrIndex].values.objectValue.keyValue.push({ key: null, value: null })
      }
      if (!this.entities.attributes[attrIndex].values.objectValue.keyValue[objKeyValueIndex].key && !this.entities.attributes[attrIndex].values.objectValue.keyValue[objKeyValueIndex].value) {
        this.entities.attributes[attrIndex].values.objectValue.keyValue = this.entities.attributes[attrIndex].values.objectValue.keyValue.filter((value, index) => index !== objKeyValueIndex)
      }
    },
    addAttributeValueObj: function (attrIndex, objValueIndex, objKeyValueIndex) {
      // =============== Object Name ====================== //
      if (this.entities.attributes[attrIndex].values.objectValue.objValue.length === objValueIndex + 1) {
        this.entities.attributes[attrIndex].values.objectValue.objValue.push({ objName: null, keyValue: [{ key: null, value: null }] })
      }
      if (!this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].objName) {
        if (this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue.filter(kv => !kv.key && !kv.value).length > 0) {
          if (this.entities.attributes[attrIndex].values.objectValue.objValue.length > 0) {
            this.entities.attributes[attrIndex].values.objectValue.objValue = this.entities.attributes[attrIndex].values.objectValue.objValue.filter((value, index) => index !== objValueIndex)
          }
        }
      }
      // =============== Key - Value ====================== //
      if (objKeyValueIndex > -1) {
        if (this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue.length === objKeyValueIndex + 1) {
          this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue.push({ key: null, value: null })
        }
        if (!this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue[objKeyValueIndex].key &&
          !this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue[objKeyValueIndex].value) {
          if (this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue.length > 0) {
            this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue = this.entities.attributes[attrIndex].values.objectValue.objValue[objValueIndex].keyValue.filter((value, index) => index !== objKeyValueIndex)
          }
        }
      }
    },
    addAttributeValueArr: function (attrIndex, arrValueIndex) {
      if (this.entities.attributes[attrIndex].values.objectValue.arrayValue.length === arrValueIndex + 1) {
        this.entities.attributes[attrIndex].values.objectValue.arrayValue.push({ key: null, value: [], inputArr: null })
      }
      if (!this.entities.attributes[attrIndex].values.objectValue.arrayValue[arrValueIndex].key) {
        this.entities.attributes[attrIndex].values.objectValue.arrayValue = this.entities.attributes[attrIndex].values.objectValue.arrayValue.filter((value, index) => index !== arrValueIndex)
      }
    },
    addAttrArrayKv: function (attrIndex, arrayObjValueIndex) {
      if (this.entities.attributes[attrIndex].values.arrayValue.keyValue.length === arrayObjValueIndex + 1) {
        this.entities.attributes[attrIndex].values.arrayValue.keyValue.push({ key: null, valueType: null, value: null, inputValue: null })
      }
      if (!this.entities.attributes[attrIndex].values.arrayValue.keyValue[arrayObjValueIndex].key && !this.entities.attributes[attrIndex].values.arrayValue.keyValue[arrayObjValueIndex].value) {
        if (this.entities.attributes[attrIndex].values.arrayValue.keyValue.length > 0) {
          this.entities.attributes[attrIndex].values.arrayValue.keyValue = this.entities.attributes[attrIndex].values.arrayValue.keyValue.filter((value, index) => index !== arrayObjValueIndex)
        }
      }
    },
    addAttrMetadata: function (attrIndex, metaIndex) {
      if (this.entities.attributes[attrIndex].metadata.length === metaIndex + 1) {
        this.entities.attributes[attrIndex].metadata.push({ name: null, value: null, type: null })
      }
      if (!this.entities.attributes[attrIndex].metadata[metaIndex].name) {
        this.entities.attributes[attrIndex].metadata = this.entities.attributes[attrIndex].metadata.filter((value, index) => index !== metaIndex)
      }
    },
    duplicateAttributes: function (attr) {
      this.entities.attributes.push(JSON.parse(JSON.stringify(attr)))
    },
    jsoneditorError: function (err) {
      console.error(err)
    },
    createEntities: function () {
      if (this.$refs.form.validate()) {
        if (this.entities.attributes.length === 0) {
          this.addEntitiesAttr()
        } else {
          api.entitiesPOST('/v2/entities/', this.filterEntities, this.$store.state.firewareServices, '/test').then((response) => {
            if (response.status === 201) {
              console.log('Created :)')
              this.$emit('action', false)
              this.snackActive = true
              this.snackMessage = 'Created'
            }
          })
        }
      }
    },
    updateEntities: function () {
      api.post(`/v2/entities/${this.entities.id}/attrs`, this.filterEntities, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 204) {
          console.log('Updated :)')
          this.snackActive = true
          this.snackMessage = 'Update Success'
        }
      })
    },
    resetValidate: function () {
      this.$refs.form.resetValidation()
      this.$emit('valid', true)
    },
    attrTypeRules: function (items, type) {
      if (items.filter(item => item === type).length > 0) {
        if (type === 'Boolean') {
          return [v => !!v || 'field must be boolean']
        } else if (type === 'Number') {
        } else if (type === 'Text') {
        } else if (type === 'geo:json') {
        } else if (type === 'geo:point') {}
      } else {
        return [v => !!v || 'field is required']
      }
    }
  },
  watch: {
    valid (newValue) {
      this.$emit('valid', newValue)
    }
  },
  created () {
  },
  mounted () {
  }
}
</script>
